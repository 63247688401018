.poimap-container {
   display: grid;
   grid-template-columns: 0.8fr 1.2fr;
   grid-template-rows: minmax(600px, 82vh);
   /* justify-content: space-between; */
   align-items: top;
   background-color: #faf9f5;
   gap: 2rem;
   padding: 10rem 2rem 0 2rem;
   width: 100%;
}

.poimap-container h1{
   max-width: 330px;
   margin-top: -0.5rem;
   margin-left: 1rem;
}

.poimap-cardarea {
   display: flex;
   flex-direction: column;
}

.svg-map {
   object-fit: fill;
   width: 100%;
   height: 100%;
}

svg circle {
   transition: all .3s ease-in-out;
}

.poicard-container {
   height: auto;
   width: 100%;
   padding: 20px 20px 20px 20px;
   box-shadow: 10px 5px 5px rgba(211, 211, 211, 0.5);
   background-color: #faf9f5;
}

.poicard-container h3 {
   text-align: center;
   font-weight: 400;
   color: #2D8DC2;
   font-size: 35px;
}

.poicard-image-container {
   display: block;
   position: relative;
   height: 0;
   overflow: hidden;
   padding-bottom: 56.25%;
}

.poicard-container img {
   object-fit: cover;
   max-height: 42vh;
   width: 100%;
   margin-bottom: 10px;
}

.poicard-transition-enter {
   opacity: 0.01;
 }
 
 .poicard-transition-enter.poicard-transition-enter-active {
   opacity: 1;
   transition: opacity 500ms ease-in;
 }
 
 .poicard-transition-leave {
   opacity: 1;
 }
 
 .poicard-transition-leave.poicard-transition-leave-active {
   opacity: 0.01;
   transition: opacity 200ms ease-in;
 }

@media screen and (max-width: 1290px) {
   .poimap-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem 5rem 0 5rem;
      gap: 1rem;
   }

   .poimap-map-container {
      width: 100%;
      height: 100%;
   }

   .poicard-container {
      position: fixed;
      padding: 10px 10px 10px 10px;
      width: 250px;
      border-radius: 10px;
      left: 5%;
      bottom: 5%;
      z-index: 9999;
   }

   .poicard-container img {
      height: 120px;
      border-radius: 10px;
   }

   .poicard-container h3 {
      text-align: center;
      font-weight: 400;
      font-size: 20px;
   }

   .poimap-cardarea {
      justify-content: center;
      width: 350px;
      margin-bottom: 4rem;
   }
}

@media screen and (max-width: 900px) {
   .poimap-container {
      padding: 5rem 2rem 0 2rem;
   }

   /* .poimap-cardarea {
      height: 600px
   }

   .poicard-container {
      margin-top: 120px;
      max-height: 400px;
      width: 320px;
      padding: 24px 20px 20px 20px;
      border-radius: 20px;
   }
   
   .poicard-container h2 {
      text-align: center;
      font-weight: 400;
      color: white;
   }
   
   .poicard-container img {
      object-fit: fill;
      height: 200px;
      border-radius: 20px;
      margin-bottom: 10px;
   } */
}