.teamtextpanel-container {
   padding: 0 10rem 5rem 10rem;
}

.teamtextpanel-container p{
   font-size: 25px;
   line-height: 2.2;
}

@media screen and (max-width: 1600px){
   .teamtextpanel-container {
      padding: 0 5rem 5rem 0;
   }
}

@media screen and (max-width: 1280px){
   .teamtextpanel-container {
      padding: 0 2rem 5rem 2rem;
   }

}