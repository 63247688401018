.teampage-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.team-content-container {
   display: grid;
   grid-template-columns: 1fr 1fr;
}

h1 {
   margin: 5rem 0 5rem 0;
}

@media screen and (max-width: 1280px){
   .team-content-container {
      display: flex;
      flex-direction: column-reverse;
   }
}