.simplecard-container {
   background-color: #faf9f5;
   display: flex;
   align-items: top;
   justify-content: space-between;
   gap: 5rem;
   padding: 5rem 5rem 5rem 5rem;
   width: 100%;
   height: fit-content;
}

.simplecard-container img{
   width: 100%;
   max-height: 100%;
   object-fit: contain;
}

.simplecard-text-wrapper {
   display: flex;
   flex-direction: column;
   align-items: left;
   min-width: 300px;
   max-width: 400px;
 }
 
.simplecard-text-wrapper h2 {
   margin-bottom: 2.5rem;
}

.simplecard-text-wrapper p {
text-align: left;
font-size: 1.2rem;
line-height: 1.8;
}

@media screen and (max-width: 1290px) {
   .simplecard-container {
      flex-direction: column;
      align-items: left;
      justify-content: left;
      gap: 2rem;
      padding: 2rem 2rem 2rem 2rem;
      height: auto;
   }

   .simplecard-container img {
      height: 100%;
      width: 100%;
   }

   .simplecard-text-wrapper {
      position: static;
      height: auto;
      margin-left: 2rem;
      margin-right: 2rem;
   }

   .simplecard-pics-wrapper {
      margin: 0 2rem;
   }

   .simplecard-text-wrapper h2 {
      font-size: 25px;
      width: 250px;
   }
}