.footer-container {
  background-color: #faf9f5;
  padding: 2rem 0 2rem 0;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: 180px;
  border-top: 3px solid rgb(45, 141, 194);
  font-size: 1.2rem;
}

#footer-logo-img {
  height: 55px;
}

.footer-logo {
  margin-left: 3rem;
}

.footer-info,
.footer-address {
  color: black;
  min-width: 150px;
}

.footer-info h3,
.footer-address h3 {
  margin-bottom: 19px;
}

.footer-container button {
  margin-right: 3rem;
}

.footer-links {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  list-style: none;
  justify-content: center;
}

.footer-nav-links {
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0.5rem;
  height: 100%;
}

.footer-nav-links:hover {
  color: rgb(45, 141, 194);
  transition: all 0.2s ease-out;
}

.footer-link-wrapper {
  display: flex;
}

@media screen and (max-width: 1090px) {
  .footer-link-wrapper {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .footer-logo {
    display: none;
  }

  .footer-container {
    padding-left: 3rem;
  }
}

@media screen and (max-width: 580px) {
  .footer-container {
    height: 500px;
    flex-direction: column;
    gap: 3rem;
    justify-content: center;
    padding: 0;
  }

  .footer-info,
  .footer-address {
    text-align: center;
    font-size: larger;
  }

  .footer-container button {
    margin-right: 0;
  }
}
