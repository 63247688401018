body {
   background-color: #faf9f5;
}

.gallery-container {
   display: flex;
   align-items: top;
   padding-top: 2rem;
   padding-bottom: 10rem;
   background-color: #faf9f5;
}

.gallery-text-wrapper {
   position: fixed;
   display: flex;
   flex-direction: column;
   align-items: left;
   width: auto;
   max-width: 400px;
   margin-left: 5.6rem;
   margin-top: 1.5rem;
 }
 
 .gallery-text-wrapper h2 {
   margin-bottom: 2.5rem;
 }
 
 .gallery-text-wrapper p {
   display: block;
   text-align: left;
   font-size: 1.2rem;
   line-height: 1.8;
 } 

.gallery-pics-wrapper img {
   object-fit: fill;
   width: 100%;
}

.image-container {
   display: block;
   position: relative;
   height: 0;
   overflow: hidden;
   padding-bottom: 56.25%;
}

.in-pic-container {
   padding-bottom:120%;
}

.gallery-pics-wrapper {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 2rem;
   width: 100%;
   margin-left: calc(400px + 5.6rem + 90px);
   margin-right: 2rem;
}

.pics-container {
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 2rem;
}

@media screen and (max-height: 830px) and (min-width: 1290px) {
   
   .gallery-text-wrapper h2 {
      margin-bottom: 2rem;
      font-size: 1.7rem;
    }
    
    .gallery-text-wrapper p {
      display: block;
      text-align: left;
      font-size: 1.1rem;
      line-height: 1.6;
    } 
}

@media screen and (max-width: 1290px) {
   .gallery-text-wrapper {
      position: static;
      height: auto;
      margin-left: 2rem;
      margin-right: 2rem;
   }

   .gallery-pics-wrapper {
      margin: 0 2rem 0 2rem;
   }

   .gallery-text-wrapper h2 {
      font-size: 25px;
    }
}