:root {
   --primary: #2D8DC2;
}

.btn {
   padding: 8px 20px;
   border-radius: 15px;
   outline: none;
   border: none;
   cursor: pointer;
   background-color: #2D8DC2;
}

.btn--primary {
   background-color: var(--primary);
   color: white;
   border: 1px solid var(--primary);
}

.btn--outline {
   background-color: #2D8DC2;
   color: white;
   padding: 8px 20px;
   border: 1px solid var(--primary);
   transition: all 0.3s ease-out;
}

.btn--medium {
   padding: 8px 20px;
   font-size: 20px;
}

.btn--large {
   padding: 12px 26px;
   font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
   background: rgba(45, 141, 194, 0.8);
   color: white;
   transition: all 0.3s ease-out;
}