.registrationform {
   display: grid;
   grid-template-columns: 0.3fr 0.7fr;
   padding: 10rem 5rem 10rem 5rem;
   gap: 10rem;
   background-color: #faf9f5;
}

.registrationform .header-wrapper {
   width: 100%;
}

.form-wrapper {
   width: 100%;
}

.registrationform h1 {
   font-size: 50px;
   margin-top: -10px;
}

.input-fields-container {
   width: 100%;
   display: grid;
   grid-template-columns: 1fr 1fr;
   gap: 3rem;
}

.input-fields-container input {
   height: 4rem;
   max-width: 420px;
   min-width: 405px;
   font-size: 1.9rem;
   border: 2px solid #2D8DC2;
   background-color: #faf9f5;
}

.input-fields-container div,
.contact-method-container,
.comments-container,
.registrationform form {
   display: flex;
   flex-direction: column;
}

.comments-container textarea {
   font-size: 1.56rem;
   background-color: #faf9f5;
}

.registrationform label {
   font-size: 1.9rem;
   margin-bottom: 0.5rem;
   color:#2D8DC2;
}

.registrationform form {
   gap: 1rem;
}

.checkbox-container {
   display: flex;
   gap: 1rem;
}

.checkbox-container label {
   font-size: 1.56rem;
}

input[type=checkbox] {
   transition: box-shadow .3s;
   width: 20px;
   height: 20px;
   appearance: none; -webkit-appearance: none; 
   border: 2px solid #2D8DC2;
 }

 input[type=checkbox]:checked { 
   box-shadow: inset 0 0 0 20px #2D8DC2;
 }

#register-privacy {
   width: 20px; height: 20px;
   border-radius: 50%;
   border: 2px solid #2D8DC2;
}

.registrationform form textarea {
   resize: none;
   border: 2px solid #2D8DC2;
}

#privacy-policy-label {
   font-size: 1.56rem;
}

.button-wrapper {
   display: flex;
   justify-content: right;
   margin-top: 1rem;
}

@media screen and (max-width: 1600px) {
   #register-realtor-label {
      font-size: 1.6rem;
      margin-bottom: 13px;
   }
}

@media screen and (max-width: 1290px) {
   .registrationform {
      display: flex;
      flex-direction: column;
   }

   .input-fields-container input {
      min-width: 0px;
   }
}

@media screen and (max-width: 900px) {
   .registrationform {
      padding: 0rem 2rem 5rem 2rem;
   }

   .input-fields-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
   }

   #register-realtor-label {
      font-size: 1.9rem;
      margin-bottom: 0;
   }

   .button-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 5rem;
   }
}