.hero-container video,
.hero-container .cover {
   object-fit: cover;
   /* object-position: 0 0; */
   height: 100%;
   width: 100%;
   position: fixed;
   z-index: -1;
}

.hero-container .contain {
   object-fit: fill;
   height: 90vh;
   width: 100%;
   position: fixed;
   z-index: -1;
}


.hero-container .mute-icon {
   position: absolute;
   top: 2%;
   left: 2%;
   cursor: pointer;
}

.hero-container {
   height: 95vh;
   width: 100%;
   position: relative;
   /* display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center; */
   /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
   /* object-fit: cover; */
}

.hero-container .fa-volume-high,
.hero-container .fa-volume-xmark {
  color: rgba(255, 255, 255, 0.5);
  font-size: 3rem;
}

@media screen and (max-width: 900px) {
   .hero-container .contain {
      object-fit: contain;
   }
}