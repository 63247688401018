.locationcards {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.header-container {
   background-color: #faf9f5;
   width: 100%;
   padding: 10rem 5.6rem;
}

.locationcarditem .reversed {
   order: -1;
}

.locationcarditem {
   display: grid;
   grid-template-columns: 1fr 1fr;
   width: 100%;
   background-color: #CBE4F2;
}

.locationcarditem img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.loc-card-text-wrapper {
   width: 100%;
   padding: 5.6rem 5.6rem;
}

.loc-card-text-wrapper h2 {
   font-size: 2rem;
   margin-bottom: 2rem;
}

.loc-card-text-wrapper p {
   font-size: 2rem;
   line-height: 1.5;
}

@media screen and (max-width: 900px) {
   .header-container {
      padding: 10rem 2rem;
   }

   .locationcarditem {
      display: flex;
      flex-direction: column;
   }

   .locationcarditem .reversed {
      order: 1;
   }

   .loc-card-text-wrapper {
      padding: 2rem 2rem;
   }

   .loc-card-text-wrapper h2 {
      font-size: 30px;
   }
   
   .loc-card-text-wrapper p {
      font-size: 1.2rem;
   }
}