.partnerpanel-container {
   padding: 0 4rem 5rem 5rem;
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-template-rows: 1fr 1fr 1fr 1fr;
   align-items: start;
   width: 100%;
}

@media screen and (max-width: 1800px){
   .partnerpanel-container {
      padding: 0 5rem 5rem 5rem;
      margin-top: 0rem;
   }
}

@media screen and (max-width: 1000px){
   .partnerpanel-container {
      padding: 0 2rem 2rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
   }

}