* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
}

#root {
  white-space: pre-line;
}

body {  min-width: 320px; }

h1 {
  display: inline-block;
  color: #2D8DC2;
  border-bottom: 2px solid #2D8DC2;
  width: fit-content;
  font-size: 2rem;
  padding-bottom: 0.5rem;
  text-align: left;
}

h2 {
  display: inline-block;
  color: #2D8DC2;
  border-bottom: 2px solid #2D8DC2;
  width: fit-content;
  font-size: 2rem;
  padding-bottom: 0.5rem;
  text-align: left;
}


.team {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.privacy-container {
  display: flex;
  flex-direction: column;
  padding: 10rem 5rem 10rem 5rem;

}

.privacy-container h1 {
  display: inline-block;
  font-size: 40px;
  color: #2D8DC2;
  text-align: left;
  width: fit-content;
  border-bottom: 2px solid #2D8DC2;
  margin-bottom: 10rem;
}

.policy-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  font-size: 1.2rem;
  max-width: 1100px;
}

@media screen and (max-width: 1290px) {
  .privacy-container {
    padding: 10rem 3rem 10rem 3rem;
  
  }
}
