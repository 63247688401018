.videoplayer {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background-color: #faf9f5;
   width: 100%;
   padding: 10rem 10rem 10rem 10rem;
}

.header-wrapper {
   width: 100%;
}

.videoplayer h1 {
   margin-bottom: 10rem;
}

.videoplayer .video-container {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100%;
   position: relative;
}

.videoplayer video {
   width: 100%;
   height: 82vh;
   object-fit: cover;
   border-radius: 30px;
}

.video-container .mute-icon {
   position: absolute;
   top: 3%;
   left: 2%;
   z-index: 1;
   cursor: pointer;
}


.videoplayer .fa-volume-high,
.videoplayer .fa-volume-xmark {
  color: rgba(255, 255, 255, 0.5);
  font-size: 3rem;
}


@media screen and (max-width: 1290px) {
   .videoplayer {
      padding: 5rem 5rem 5rem 5rem;
   }

   .videoplayer h1 {
      margin-bottom: 5rem;
   }

   /* .videoplayer video {
      height: auto;
      object-fit: contain;
      border-radius: 30px;
   } */
}

@media screen and (max-width: 900px) {
   .videoplayer {
      padding: 5rem 2rem 5rem 2rem;
   }
}