.partneritem-container {
   
   display:flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

}

.partneritem-img-container {
   height: 150px;
}

.partneritem-img-container img {
   object-fit: contain;
   width: 100%;
   height: 100%;
   max-width: 400px;
}

.partneritem-container h2 {
   border-bottom: none;
   padding-top: 5px;
   color: #faf9f5;
   background-color: #2D8DC2;
   width: 220px;
   text-align: center;
   margin-top: 1rem;
   font-size: 1.75rem;
}

.partneritem-container h2:hover + .hide {
   background-color: #2D8DC2;
   
}

.hide {
   background-color: none;
   width: 220px;
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-bottom: 1rem;
   transition: all 0.4s ease;
}

.partneritem-container p {
   color: #faf9f5;
   font-size: 1.2rem;
}