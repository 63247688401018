.floorplan {
   display: flex;
   flex-direction: column;
   background-color: #faf9f5;
   padding: 10rem 0 10rem 5rem;
}

.floorplan h1 {
   margin-bottom: 3rem;
}

.floorarea-container {
   display: flex;
   align-items: top;
   justify-content: left;
}

.floorplan-img-container {
   width: 100%;
   height: 100%;
}

.menu-item button {
   color: black;
   background-color: #faf9f5;
   border: none;
   cursor: pointer;
   margin-bottom: 2rem;
   &.active{
      color: rgb(45, 141, 194);}
}

.menu-item button:hover {
   color: #2D8DC2;
}

.menu-item button{
   outline-width: 3px;
   font-size: 30px;
   width: 230px;
   height: 40px;
}

.floorplan li {
   display: flex;
   width: 100%;
   justify-content: space-between;
   align-items: center;
   list-style: none;
   font-size: 1.5rem;
   line-height: 1.75;
}

.floorplan li span {
   display: inline-block;
   width: fit-content;
}

.floorplan .sqft-span {
   margin-right: 2rem;
}

.floorplan .floorarea-item {
   color:#808080;
   border-top: 2px solid #808080;
}

.floorplan .floorarea-item-totalarea {
   border-bottom: 2px solid #808080;
}

.floorarea-container img {
   object-fit: contain;
   width: 100%;
   max-height: 85vh;
   min-height: 380px;
}

.floorarea-dropdown {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.floorarea-dropdown label {
   font-size: 2rem;
   color: #2D8DC2;
   display: block;
   margin-bottom: 2rem;
   width: 300px;
}

.download-button {
   margin-top: 3rem;
   display: inline-block;
   width: 230px;
   height: 75px;
   font-size: 35px;
   border-radius: 2rem;
   border: none;
   color: black;
   background-color: #CBE4F2;
   cursor: pointer;
}

@media screen and (max-height: 700px) {
   .menu {
      margin-top: 0rem;
   }

   .menu-item button {
      margin-top: 0rem;
   }

}

@media screen and (max-width: 1290px) {
   .floorplan {
      padding: 3rem 3rem 3rem 3rem;
   }

   .floorarea-dropdown {
      height: 85vh;
   }

   .floorarea-dropdown {
      gap: 3rem;
   }

   .floorarea-container {
      display: flex;
      flex-direction: column;
      height: auto;
      align-items: flex-start;
      justify-content: flex-start;
   }

   .floorplan li {
      font-size: 25px;
   }

   .download-button {
      margin-top: 2rem;
      height: 43px;
      border-radius: 15px;
   }
}