.navbar {
   background: #faf9f5;
   height: 100px;
   display: grid;
   grid-template-columns: 1fr;
   justify-content: left;
   align-items: center;
   font-size: 1.2rem;
   position: sticky;
   top: 0;
   z-index: 999;
 }
 
 .navbar-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 80px;
 }

 #karnaby-logo-img {
  height: 70px;
 }
 
 .navbar-logo {
   color: black;
   justify-self: start;
   margin-left: 3rem;
   cursor: pointer;
   display: flex;
   align-items: center;
 }
 
 .fa-typo3 {
   margin-left: 0.5rem;
   font-size: 1.8rem;
 }

 .nav-menu-container {
  display: flex;
  align-items: center;
  margin-right:3rem;
 }
 
 .nav-menu {
   display: grid;
   grid-template-columns: repeat(8, auto);
   grid-gap: 10px;
   list-style: none;
   text-align: center;
   width: 60vw;
   justify-content: end;
   margin-right: 2rem;
 }
 
 .nav-item {
   height: 80px;
 }
 
 .nav-links {
   color: black;
   display: flex;
   align-items: center;
   text-decoration: none;
   padding: 0.5rem 1rem;
   height: 100%;
   &.active{
    color: rgb(45, 141, 194);
  }
 }
 
 .nav-links:hover {
   color: rgb(45, 141, 194);
   transition: all 0.2s ease-out;
 }
 
 .fa-bars {
   color: black;
 }
 
 .nav-links-mobile {
   display: none;
 }
 
 .menu-icon {
   display: none;
 }

 @media screen and (max-width: 1360px) {
  .nav-menu {
    grid-gap: 5px;
  }
 }
 
 @media screen and (max-width: 1290px) {

   .nav-menu {
     display: flex;
     flex-direction: column;
     justify-content: center;
     width: 100%;
     height: 50vh;
     position: absolute;
     top: 100px;
     left: -200%;
     opacity: 1;
     transition: all 0.5s ease;
     box-shadow: 0 6px 4px -3px lightgray;
     min-height: 570px;
   }
 
   .nav-menu.active {
     background: #faf9f5;
     left: 0;
     opacity: 1;
     transition: all 0.5s ease;
     z-index: 1;
     border-top: 1px solid black;
   }

   .nav-item {
    height: 60px;
   }
 
   .nav-links {
     text-align: center;
     padding: 0.5rem;
     padding-top: 1rem;
     width: 100%;
     display: table;
   }
 
   .nav-links:hover {
     background-color: rgb(45, 141, 194);
     color: white;
     border-radius: 0;
   }
 
   .navbar-logo {
     position: absolute;
     top: -20px;
     left: -22px;
     transform: translate(25%, 50%);
   }
 
   .menu-icon {
     display: block;
     position: absolute;
     top: 10px;
     right: 30px;
     transform: translate(-100%, 60%);
     font-size: 1.8rem;
     cursor: pointer;
   }
 
   .fa-times {
     color: black;
     font-size: 2rem;
   }
 
   .nav-links-mobile {
     display: block;
     text-align: center;
     margin: 1rem auto;
     border-radius: 4px;
     width: 40%;
     text-decoration: none;
     font-size: 1.5rem;
     background-color: transparent;
     color: #2D8DC2;
     padding: 7px 10px;
     border: 1px solid #2D8DC2;
     transition: all 0.3s ease-out;
   }
 
   .nav-links-mobile:hover {
     background: #2D8DC2;
     color: white;
     transition: 250ms;
   }
 }
 